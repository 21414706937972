var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.buttonAttrs)?_vm._t("button",function(){return [_c('button',_vm._b({staticClass:"btn btn-default",attrs:{"data-testid":"toggle"},on:{"click":_vm.toggle}},'button',_vm.buttonAttrs,false),[_c('span',{class:_vm.value,attrs:{"data-testid":"toggle-icon"}})])]},{"toggle":_vm.toggle}):_vm._e(),_c('FloatPanel',_vm._b({attrs:{"id":"float-panel","draggable":true,"handleSelf":true},on:{"open":_vm.loadMore}},'FloatPanel',_vm.panel,false,true),[_c('div',{attrs:{"id":"wrapper"}},[_c('div',{staticClass:"nav-tabs-custom"},[_c('ul',{staticClass:"nav nav-tabs"},_vm._l((_vm.libraries),function(lib,index){return _c('li',{key:index,class:{ active: index == 0 },attrs:{"role":"presentation","data-testid":"tab"}},[_c('a',{attrs:{"href":'#' + lib.prefix,"data-toggle":"tab"},on:{"click":function($event){_vm.currentTab = lib.name}}},[_vm._v(_vm._s(lib.name))])])}),0),_c('div',{staticClass:"input-group input-group-sm",staticStyle:{"margin":"0.8em 0"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"form-control",attrs:{"type":"search","placeholder":_vm.$t('search'),"data-testid":"search"},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}}),_c('div',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn btn-default",attrs:{"data-testid":"reset"},on:{"click":function($event){_vm.query = ''}}},[_c('span',{staticClass:"fa fa-remove"})])])]),_c('div',{staticClass:"tab-content"},_vm._l((_vm.libraries),function(lib,index){return _c('div',{key:index,class:['tab-pane', { active: index == 0 }],attrs:{"id":lib.prefix,"data-testid":"tab-content"}},[_c('div',{staticClass:"icon-info"},[_c('ul',{staticClass:"clearfix"},_vm._l((_vm.loadedIcons[lib.prefix]),function(icon,index){return _c('li',{key:index,attrs:{"title":icon.name +
                      (icon.aliases.length
                        ? ` (${_vm.$tc(
                            'alias',
                            2
                          ).toLowerCase()}: ${icon.aliases.join(', ')})`
                        : ''),"data-testid":"icon"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.select(icon)}}},[_c('span',{class:`icon ${_vm.currentLib.prefix} ${_vm.currentLib.prefix}-${icon.name}`}),_c('span',{staticClass:"icon-name",attrs:{"data-testid":"icon-name"}},[_vm._v(_vm._s(icon.name))])])}),0),(_vm.loading)?_c('span',{staticClass:"fa fa-refresh fa-spin",attrs:{"id":"loading-icon"}}):_vm._e(),(
                  !_vm.loading && _vm.loadedIcons[lib.prefix].length < _vm.icons.length
                )?_c('button',{staticClass:"btn btn-link text-center",staticStyle:{"width":"100%"},attrs:{"data-testid":"load-more"},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("load_more"))+" ")]):_vm._e()])])}),0)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }