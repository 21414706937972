<template>
  <div style="position: relative">
    <slot name="button" v-bind:toggle="toggle" v-if="buttonAttrs">
      <button
        class="btn btn-default"
        @click="toggle"
        data-testid="toggle"
        v-bind="buttonAttrs"
      >
        <span :class="value" data-testid="toggle-icon"></span>
      </button>
    </slot>
    <FloatPanel
      id="float-panel"
      v-bind.sync="panel"
      :draggable="true"
      :handleSelf="true"
      @open="loadMore"
    >
      <div id="wrapper">
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs">
            <li
              v-for="(lib, index) in libraries"
              :key="index"
              role="presentation"
              :class="{ active: index == 0 }"
              data-testid="tab"
            >
              <a
                :href="'#' + lib.prefix"
                data-toggle="tab"
                @click="currentTab = lib.name"
                >{{ lib.name }}</a
              >
            </li>
          </ul>
          <div class="input-group input-group-sm" style="margin: 0.8em 0;">
            <input
              v-model="query"
              type="search"
              class="form-control"
              :placeholder="$t('search')"
              data-testid="search"
            />
            <div class="input-group-btn">
              <button
                class="btn btn-default"
                @click="query = ''"
                data-testid="reset"
              >
                <span class="fa fa-remove"></span>
              </button>
            </div>
          </div>
          <div class="tab-content">
            <div
              v-for="(lib, index) in libraries"
              :key="index"
              :class="['tab-pane', { active: index == 0 }]"
              :id="lib.prefix"
              data-testid="tab-content"
            >
              <div class="icon-info">
                <ul class="clearfix">
                  <li
                    v-for="(icon, index) in loadedIcons[lib.prefix]"
                    :key="index"
                    :title="
                      icon.name +
                        (icon.aliases.length
                          ? ` (${$tc(
                              'alias',
                              2
                            ).toLowerCase()}: ${icon.aliases.join(', ')})`
                          : '')
                    "
                    @click.stop.prevent="select(icon)"
                    data-testid="icon"
                  >
                    <span
                      :class="
                        `icon ${currentLib.prefix} ${currentLib.prefix}-${icon.name}`
                      "
                    ></span>
                    <span class="icon-name" data-testid="icon-name">{{
                      icon.name
                    }}</span>
                  </li>
                </ul>
                <span
                  id="loading-icon"
                  class="fa fa-refresh fa-spin"
                  v-if="loading"
                ></span>
                <button
                  class="btn btn-link text-center"
                  @click="loadMore"
                  v-if="
                    !loading && loadedIcons[lib.prefix].length < icons.length
                  "
                  style="width: 100%"
                  data-testid="load-more"
                >
                  {{ $t("load_more") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FloatPanel>
  </div>
</template>

<script>
import FloatPanel from "./float-panel";
import IconLibrary from "@/assets/dashboard/icons";

export default {
  name: "IconLibrary",
  props: {
    buttonAttrs: {
      type: Object,
      required: false,
      default: () => ({})
    },
    loadAmount: {
      type: Number,
      required: false,
      default: 9
    },
    value: {
      type: String,
      required: false,
      default: "glyphicon glyphicon-th-large"
    },
    panelOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    FloatPanel
  },
  data() {
    return {
      panel: {
        open: false,
        defaultPosition: { left: "-246px", top: "-237px" }
      },
      currentTab: IconLibrary[0].name,
      loadedIcons: {},
      query: ""
    };
  },
  computed: {
    libraries() {
      return IconLibrary;
    },
    currentLib() {
      return this.libraries.find((lib) => lib.name == this.currentTab);
    },
    icons() {
      return this.currentLib.icons.filter((icon) =>
        this.query
          ? icon.name.indexOf(this.query) != -1 ||
            this.$utils.queryStrAtr(this.query, icon.aliases)
          : true
      );
    },
    loading() {
      return !this.loadedIcons[this.currentLib.prefix]?.length && !this.query;
    }
  },
  watch: {
    query: "reloadIcons",
    currentLib: "reloadIcons",
    panelOpen: {
      immediate: true,
      handler(val) {
        this.panel.open = val;
      }
    },
    "panel.open"(val) {
      if (val != this.panelOpen) {
        this.$emit("update:panelOpen", val);
      }
      if (!val) {
        this.reset();
      }
    }
  },
  methods: {
    toggle() {
      this.panel.open = !this.panel.open;
    },
    unloadIcons(all = true) {
      if (all)
        this.loadedIcons = this.libraries.reduce((libs, lib) => {
          libs[lib.prefix] = [];
          return libs;
        }, {});
      else this.loadedIcons[this.currentLib.prefix] = [];
    },
    loadMore() {
      this.loadedIcons[this.currentLib.prefix].push(
        ...this.icons.slice(
          this.loadedIcons[this.currentLib.prefix].length,
          this.loadedIcons[this.currentLib.prefix].length + this.loadAmount
        )
      );
    },
    reloadIcons() {
      this.unloadIcons(false);
      this.loadMore();
    },
    reset() {
      this.query = "";
      this.$nextTick(() => this.unloadIcons());
    },
    select(icon) {
      this.panel.open = false;
      this.$emit(
        "input",
        `${this.currentLib.prefix} ${this.currentLib.prefix}-${icon.name}`
      );
    }
  },
  created() {
    this.loadedIcons = this.libraries.reduce((libs, lib) => {
      libs[lib.prefix] = [];
      return libs;
    }, {});
  }
};
</script>

<style lang="scss" scoped>
#float-panel {
  padding: 1em;

  #wrapper {
    width: 220px;
    min-height: 50px;

    .nav-tabs-custom {
      margin-bottom: 0;
      box-shadow: none;

      .tab-content {
        padding: 10px 0 0 0;
      }

      .nav > li > a {
        padding: 6px 10px;
      }

      .icon-info {
        overflow: hidden auto;
        max-height: 240px;
        clear: both;

        #loading-icon {
          font-size: 1.2em;
          margin: 2px auto;
          display: block;
          text-align: center;
        }

        ul {
          list-style: none;
          margin-bottom: 0;
          padding-left: 0;

          li {
            float: left;
            width: 33%;
            height: 65px;
            padding: 10px;
            text-align: center;
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-direction: column;
            overflow: hidden;

            &:hover {
              background-color: rgba(86, 61, 124, 0.1);
            }

            .icon {
              font-size: 1.5em;
              margin-bottom: 5px;
            }

            .icon-name {
              font-size: 12px;
              display: block;
              line-height: 1em;
            }
          }
        }
      }
    }
  }
}
</style>
